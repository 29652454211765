<template>
  <v-dialog v-model="active" max-width="1200" class="integration-dialog">
    <template v-slot:default>
      <v-card>
        <v-toolbar color="primary" elevation="0" dark>
          <v-icon class="mr-2"> mdi-lan-connect </v-icon>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="active = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-show="loading" class="loading-container pt-12">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </div>
          <v-row v-show="!loading">
            <v-col cols="3">
              <v-list>
                <v-list-group :value="true" prepend-icon="mdi-lan-connect">
                  <template v-slot:activator>
                    <v-list-item-title>Integrações</v-list-item-title>
                  </template>
                  <v-list-item-group v-model="menu">
                    <v-list-item>
                      <v-list-item-title>
                        Reconhecimento de voz (Iara)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        Laudo estruturado (nReport)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        Prescrição eletrônica (Memed)
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </v-col>
            <v-col>
              <div class="py-4" style="min-height: 400px">
                <div class="" v-show="menu == null">
                  <p>Selecione uma opção</p>
                </div>
                <VoiceRecognitionForm
                  ref="voiceRecognitionForm"
                  v-show="menu == 0"
                />
                <StructuredReportForm
                  ref="structuredReportForm"
                  v-show="menu == 1"
                />
                <PrescriptionForm ref="prescriptionForm" v-show="menu == 2" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" color="secondary" @click="active = false">
            Cancelar
          </v-btn>
          <v-btn
            small
            elevation="0"
            color="primary"
            :loading="loading"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import VoiceRecognitionForm from './voice_recognition_form'
import StructuredReportForm from './structured_report_form'
import PrescriptionForm from './prescription_form'
import USER_ORGANIZATION_ENUM from '@/enumerations/user_organization_enum'
import Api from '@/resources/NajaSocialApi'

export default {
  name: 'IntegrationDialog',
  components: { VoiceRecognitionForm, StructuredReportForm, PrescriptionForm },
  data: () => ({
    user: {
      name: null,
      id: null,
    },
    active: false,
    loading: false,
    title: 'Integrações',
    menu: 0,
    form: {},
  }),
  methods: {
    save() {
      if (this.menu == 0) {
        this.$refs.voiceRecognitionForm.save()
      }

      if (this.menu == 1) {
        this.$refs.structuredReportForm.save()
      }

      if (this.menu == 2) {
        this.$refs.prescriptionForm.save()
      }
    },

    async open(user) {
      const userRule = USER_ORGANIZATION_ENUM.rules.find(
        x => x.value == user.role_value
      )?.key
      if (userRule != 'USER_ROLE_DOCTOR') {
        this.$root.$children[0].toast(
          'Integrações não diponíveis para este usuário.'
        )
        return
      }

      this.user = user
      this.menu = 0
      this.title = 'Integrações'
      if (user.name) {
        this.title = `${this.title} (${user.name})`
      }

      this.active = true

      await this.$sleep(10)
      this.$refs.voiceRecognitionForm.clearForm()
      this.$refs.structuredReportForm.clearForm()
      this.$refs.prescriptionForm.clearForm()

      this.fetch()
    },

    fetch() {
      this.loading = true
      Api.users
        .fetch(this.user.user_id)
        .then(async response => {
          this.$refs.voiceRecognitionForm.setForm(
            this.user,
            response.data.user.voice_settings
          )
          this.$refs.structuredReportForm.setForm(
            this.user,
            response.data.user.credentials_for_structured_report
          )
          this.$refs.prescriptionForm.setForm(
            this.user,
            response.data.user.memed_settings
          )
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao buscar usuário')
            this.active = false
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped>
.integration-dialog >>> .v-card__text {
  min-height: 500px;
}
</style>
