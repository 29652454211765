<template>
  <div class="users nj-grid">
    <IntegrationDialog ref="integrationDialog" />
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Usuário"
      text="Deseja realmente excluir usuário?"
    />
    <div class="header">
      <span class="title">Listagem de Usuários</span>
      <v-form @submit.prevent="search()">
        <v-text-field
          append-icon="mdi-magnify"
          placeholder="Pesquisar"
          outlined
          autofocus
          hide-details
          dense
          class="ma-0 pa-0"
          v-model="searchTerm"
        >
        </v-text-field>
      </v-form>
      <v-btn small color="primary" elevation="0" to="/users/new">
        <v-icon left> mdi-plus </v-icon>
        Novo Usuário
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="users"
      class="nj-table"
      :items-per-page="30"
      :footer-props="footerProps"
      :loading="loading"
      :options.sync="options"
      loading-text="Buscando..."
      ref="userDataTable"
      @update:page="search()"
      :page="pagination.current_page"
      :server-items-length="pagination.total_entries"
    >
      <template v-slot:item.active="{ item }">
        {{ item.active ? 'Ativo' : 'Suspenso' }}
      </template>
      <template v-slot:item.controls="{ item }">
        <div style="width: 160px">
          <v-btn
            :to="{ name: 'UserShow', params: { id: item.id } }"
            title="Visualizar"
            icon
          >
            <v-icon color="green">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            :to="{ name: 'UserEdit', params: { id: item.id } }"
            title="Editar"
            icon
          >
            <v-icon color="orange">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn
            @click="$refs.integrationDialog.open(item)"
            title="Integrações"
            icon
          >
            <v-icon color="orange">mdi-lan-connect</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import IntegrationDialog from './integration_dialog'
import USER_ORGANIZATION_ENUM from '@/enumerations/user_organization_enum'

export default {
  name: 'Users',
  components: { IntegrationDialog },

  data: () => ({
    searchTerm: '',
    loading: false,
    users: [],
    options: {},
    pagination: {
      current_page: 1,
      total_entries: 0,
    },
    headers: [
      { text: 'Login', value: 'username' },
      { text: 'Nome', value: 'name' },
      { text: 'E-mail', value: 'email' },
      { text: 'Tipo', value: 'role' },
      { text: 'Status', value: 'active' },
      { text: 'Ações', value: 'controls', sortable: false },
    ],
    footerProps: {
      'disable-items-per-page': true,
      'items-per-page-all-text': '',
      'items-per-page-options': [30, -1],
      'items-per-page-text': ''
    },
  }),

  methods: {
    remove(item) {
      if (!this.$hasPermission('naja_app_users_destroy')) {
        alert('Acesso negado')
        return
      }
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.userOrgaizations
            .destroy(item.id)
            .then(async () => {
              // this.$toast.show('Usuário removido com sucesso', 'success');
              // this.$router.push({ name: 'users' });
              this.$scrollTop()
              this.search()
            })
            .catch(error => {
              if (error.response) {
                // this.$toast.show('Erro ao remover usuário', 'error');
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    search() {
      this.loading = true
      const pageNumber = this.$refs.userDataTable.options.page || 1
      const query = {
        page: pageNumber,
        q: {
          role_in: USER_ORGANIZATION_ENUM.rules
            .filter(x =>
              ['USER_ROLE_COLLABORATOR', 'USER_ROLE_DOCTOR'].includes(x.key)
            )
            .map(x => x.value),
          user_name_or_user_username_or_email_cont: this.searchTerm,
          s: ['created_at desc'],
        },
      }

      Api.userOrgaizations
        .search(query)
        .then(response => {
          this.users = response.data.user_organizations
          this.pagination = response.data.pagination
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>
