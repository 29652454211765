import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: '//api.memed.com.br/v1',
  headers: { 'Content-Type': 'application/json' },
})

export const endpoints = {
  especialidades: '/especialidades',
}

export default {
  axios: axiosInstance,
  baseURL: axiosInstance.defaults.baseURL,
  especialidades: params =>
    axiosInstance.get(endpoints.especialidades, { params: params }),
}
