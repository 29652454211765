<template>
  <div>
    <div v-show="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form @submit.prevent="save">
      <v-switch
        v-model="form.enable"
        label="Ativo"
        color="primary"
        class="mt-0 mb-2"
      ></v-switch>
      <v-text-field
        v-model="form.external_id"
        label="External Id"
        disabled
        required
        dense
        outlined
      ></v-text-field>
    </v-form>
    <div v-if="memedUserFound">
      <p>Usuário já integrado com Memed</p>
      <v-btn
        small
        dark
        elevation="0"
        class="mb-4"
        color="red"
        :loading="loadingRemove"
        @click.prevent="removeMemedUser"
      >
        Remover integração
      </v-btn>
    </div>
    <v-form @submit.prevent="createDoctor" v-else v-show="!loading">
      <p class="text-subtitle-1">Prescrição eletrônica (Memed)</p>
      <v-btn
        small
        elevation="0"
        type="submit"
        class="mb-4"
        color="primary"
        :loading="loadingCreate"
      >
        Iniciar integração com Memed
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import MemedApi from '@/resources/MemedApi'
import Api from '@/resources/NajaSocialApi'
import Setting from '@/helpers/setting'

export default {
  name: 'PrescriptionForm',
  data: () => ({
    user: {
      name: null,
      id: null,
      user_id: null,
    },
    loading: false,
    loadingCreate: false,
    loadingRemove: false,
    especialidades: [],
    memedUserFound: false,
    form: {
      enable: false,
      external_id: null,
    },
  }),

  methods: {
    save(showConfirmMsg = true) {
      const data = {
        user: {
          memed_settings: Setting.reverse(this.form),
        },
      }

      Api.users
        .save(this.user.user_id, data)
        .then(() => {
          if (showConfirmMsg) {
            this.$root.$children[0].toast('Configuração salva com sucesso')
          }
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar configuração')
          } else {
            throw error
          }
        })
    },

    clearForm() {
      this.loading = false
      this.memedUserFound = false
      this.user = {
        name: null,
        id: null,
        user_id: null,
      }
      this.form = {
        enable: false,
        external_id: null,
      }
    },

    setForm(user, form) {
      this.user = user
      this.form = {
        ...this.form,
        ...form,
      }

      if (this.form.external_id == null) {
        this.form.external_id = `naja-${this.user.user_id}`
      }

      this.getMemedUser()
    },

    getMemedUser() {
      this.loading = true
      const externalId = `naja-${this.user.user_id}`
      Api.memed
        .getUser(externalId)
        .then(() => {
          this.memedUserFound = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    removeMemedUser() {
      this.loadingRemove = true
      const externalId = `naja-${this.user.user_id}`
      Api.memed
        .removeUser(externalId)
        .then(() => {
          this.memedUserFound = false
          this.$root.$children[0].toast('Integração removia com sucesso')

          // clear form and save
          this.form = {
            enable: false,
            external_id: null,
          }
          this.save(false)
        })
        .finally(() => {
          this.loadingRemove = false
        })
    },

    getEspecialidades() {
      this.loading = true
      MemedApi.especialidades()
        .then(async response => {
          this.especialidades = response.data.data
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao buscar especialidades')
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    createDoctor() {
      this.loadingCreate = true

      const name = this.user.name.split(' ')[0]
      const lastName = this.user.name.replace(name, '')
      const data = {
        data: {
          type: 'usuarios',
          attributes: {
            external_id: `naja-${this.user.user_id}`,
            nome: name,
            sobrenome: lastName,
            cpf: this.user.cpf,
            email: this.user.email,
          },
        },
      }

      Api.memed
        .createDoctor(data)
        .then(() => {
          this.$root.$children[0].toast('Integração realizada com sucesso')
          this.memedUserFound = true

          this.save(false)
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast(error.response.data.errors[0])
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loadingCreate = false
        })
    },
  },
}
</script>
