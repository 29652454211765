<template>
  <v-form @submit.prevent="save">
    <span class="text-subtitle-1">Reconhecimento de voz (Iara)</span>
    <v-switch
      v-model="form.enable"
      label="Ativo"
      color="primary"
      class="mt-0 mb-2"
    ></v-switch>
    <v-text-field
      v-model="form.login"
      label="Login"
      required
      dense
      outlined
    ></v-text-field>
    <v-text-field
      v-model="form.senha"
      label="Senha"
      required
      dense
      outlined
    ></v-text-field>
  </v-form>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import Setting from '@/helpers/setting'
export default {
  name: 'VoiceRecognitionForm',
  data: () => ({
    loading: false,
    user: {
      id: null,
      name: null,
      user_id: null,
    },
    form: {
      enable: false,
      login: null,
      senha: null,
      servico: 'iara',
    },
  }),

  methods: {
    clearForm() {
      this.loading = false
      this.user = {
        id: null,
        name: null,
        user_id: null,
      }
      this.form = {
        enable: false,
        login: null,
        senha: null,
        servico: 'iara',
      }
    },

    setForm(user, data) {
      this.user = user
      this.form = {
        ...this.form,
        ...data,
      }
    },

    save() {
      this.loading = true
      const data = {
        user: {
          voice_settings: Setting.reverse(this.form),
        },
      }

      Api.users
        .save(this.user.user_id, data)
        .then(() => {
          this.$root.$children[0].toast('Configuração salva com sucesso')
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar configuração')
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
