export default {
  parse: properties => {
    const obj = {}
    if (properties != null) {
      properties.split(';').forEach(item => {
        const key = item.split('=')[0].trim()
        let value = item.replace(`${key}=`, '').trim()

        if (value === 'true' || value === 'false') {
          value = JSON.parse(value.toLowerCase())
        }

        if (value === 'null') {
          value = null
        }

        obj[key] = value
      })
    }

    return obj
  },

  reverse: obj => {
    let str = ''

    if (obj != null) {
      Object.keys(obj).forEach(key => {
        str = str.concat(`${key}=${obj[key]};`)
      })
    }

    return str
  },
}
